import { sequences, state } from 'cerebral'
import { updateAgeRating } from '../../../utils/ageRating/ageRatingUtil'
import { wait } from '@/utils'
import { loadUsers } from '@/utils/userLoader'
import { setMarketingMediums } from '../../intl/actions'
import { getAgeRatingByUid } from '../../posters/showInRoom'
import { updOnline } from './updOnline'
import { isRestrictedMode, SPECIAL_UID } from '../../../utils/api/PWAUtil'
import { isModeration } from '../../../utils/moderationUtil'
import { loadUsersAndProfiles } from '../../../utils/userLoader'
import { clearAllPosters } from '../../../utils/posters/clearAllPosters'
import { getState, setStoreWrapper } from '../../../utils/StoreManager'
import { setCyberPosters } from '../../../utils/cyber/cyberUtil'
import { setAllLives } from '../../../zustand/useLivesStore'
import { getMyUid } from '../../../utils/my'
import { closeAllPanels } from '../../../components/Panels/createPanelUtil'

let wasConnect = false

export const onconnect = async ({ store, get, path, props }) => {
  wasConnect = true
  for (let i = 0; i < 5; i++) {
    try {
      return handleOnConnect({ store, get, path, props })
    } catch (e) {
      console.log('@@@ onconnect error:', e)
      await wait(1000)
    }
  }
}

export const wasConnection = () => wasConnect


export let firstLoadStreams = true
export const updateStreamsAnt = (streams) => {
  let count = 0
  if (!isRestrictedMode() || isModeration) {
    delete streams[SPECIAL_UID]
  }
  for (const uid in streams) {
    streams[uid].uid = uid
    count++
    if (!streams[uid].streamId) {
      streams[uid].streamId = streams[uid].stream_id
    }
  }

  const myUid = getMyUid()
  const proStream = getState('room.proBroadcast')
  const streamInRoom = getState('room.streamInRoom')
  const companionUid = getState('room.companionUid')
  const currentStreams = getState('posters.raw')

  if (streams[myUid] && !streamInRoom && !proStream) {
    delete streams[myUid]
  }

  //если у меня есть собеседник, и его стрима не стало в списке,
  //скорее всего он еще не стартовал и мы его ждем
  if (companionUid && !streams[companionUid]) {
    const bd = currentStreams[companionUid]
    if (bd && bd.waitForStream) {
      console.log('STILL WHAITING ' + companionUid)
      streams[companionUid] = bd
    }
  }

  //если я жду про трансляцию, и его стрима не стало в списке,
  //скорее всего он еще не стартовал и мы ее ждем
  if (proStream) {
    const bd = currentStreams[myUid]
    if (bd && bd.waitForStream) {
      //если появилась полноценная трансляция, то закроем все панели
      if (streams[myUid]) {
        closeAllPanels()
      } else {
        console.log('STILL WHAITING ' + myUid)
        streams[myUid] = bd
      }
    }
  }

  loadUsersAndProfiles(
    Object.keys(streams),
    () => {
      setAllLives(streams)
      setStoreWrapper('posters.raw', streams)
      setStoreWrapper('posters.antStreamsCount', count)
      if (firstLoadStreams) {
        firstLoadStreams = false
        setCyberPosters()
        setStoreWrapper('livesLoaded', true)
      }
    }
  )
}

const handleOnConnect = ({ store, get, path, props }) => {
  // const route = get(state`app.route`)
  // const roomId = get(state`app.roomId`)
  const {
    cards,
    country,
    streams,
    paidBroadcasts,
    online,
    privates,
    totalCount,
    streamsAnt
  } = props.params
  //
  store.set('posters.privates', privates)
  store.set('socket.isConnected', true)
  // // store.set('buffs.cards', cards)
  //
  // store.set('auth.lastModerRating', getAgeRatingByUid(get(state`auth.uid`)))
  //
  // // updateStreamsAnt(streamsAnt)
  //
  // if (country) {
  //   store.set('intl.country', country.toLowerCase())
  // } else {
  //   store.set('intl.country', 'us')
  // }
  //
  // updateAgeRating({ store, get })
  // setMarketingMediums({ store, get })
  //
  // // try {
  // //   updOnline({ props: { online, totalCount }, store, get })
  // // } catch (error) {
  // //   console.warn('cant parse online', online, error)
  // // }
  //
  // if (route === 'room') {
  //   return path.inRoom({ roomId })
  // } else {
    //TODO временный церебрал костыль
    return path.inOtherRoute()
  // }
}
