// import { addGameCard, cardGameNewCards, cardGameWinner } from './cardGameUtil'
// import { startGame } from './cardGameAPI'
// import { syncGame } from './cardGameTick'
// import { loadMyEnergy } from '../energy/energyUtil'

import { getSessionId, isMe } from '../utils'
import { loadMyEnergy, updateMyEnergy } from '../utils/energy/energyUtil'
import { updateStreamsAnt, updOnline } from '../modules/socket/actions'
import { isLocalHost } from '../utils/urlUtils'
import { closeCurrentPanel, createPanelUniversal } from '../components/Panels/createPanelUtil'
import { ANOTHER_LOGIN, CONNECTION_LOST } from '../components/Panels/types'
import config from '@/config'
import { getCurrentRoomId } from '../utils/navigation/navigationUtil'
import { openPosters } from '../utils/navigateUtil'
import { checkIncomeNotification } from '../modules/auth/actions/notificationUtil'
import { state } from 'cerebral'
import { getState, setStoreWrapper } from '../utils/StoreManager'
import { callEnds, startCall } from '../utils/call/callUtil'
import { setAccountBan } from '../utils/ban/accountBanUtil'
import { balanceChangedPurchasedCoins } from '../utils/balanceUtil'
import { checkChatMessage } from '../modules/chat/actions'
import { isUserMessageBanned } from '../utils/ban/banMessageCheck'
import { onNewMessage2 } from '../modules/chat/actions/onNewMessage'
import { addBuff2, dellBuff2 } from '../modules/buffs/actions'
import { animatedLikeReceived } from '../components/Levels/animatedLike'
import { addFan, dellFan } from '../modules/fans/fansUtil'
import { vipPurchase } from '../utils/userUtil'
import { uncacheUser2 } from '../modules/app/actions'
import { transaction } from '../utils/paymentUtil'
import { updateEXP } from '../components/Levels/EXPManager'
import { deleteChatMessage } from '../utils/chat/chatUtil'
import { addGiftsMessage2 } from '../modules/chat/actions/addGiftsMessage'
import { addLocalReview, delLocalReview } from '../utils/showcase/ratingUtil'
const { ftCoreWSSUrl } = config
let instance

const LOCAL = true
export function createCoreAPIWSS() {
  if (instance) {
    instance.close()
  }
  authSession = null
  instance = null
  instance = new WebSocket((isLocalHost() && LOCAL) ? 'ws://localhost:8000/ws' : 'wss://ft-core-api-dev.faminta.com/ws')//ftCoreWSSUrl)
  instance.onopen = () => {
    authCoreSocket()
    //TODO временно отключим
    closeCurrentPanel()
    setTimeout(()=>{
      //передадим вход в комнату, если я в комнате
      const roomId = getCurrentRoomId()
      if (roomId) {
        sendCorApiWsMessage('goInRoom', { roomId })
      }
    }, 500)
  }
  instance.onmessage = ({ data }) => onmessage({ data })
  instance.onclose = ({ wasClean, code, reason }) => onclose({ wasClean, code, reason })
  instance.onerror = ({ message }) => {
    console.log(message)
  }
}

async function onmessage(data) {
  let json
  try {
    json = await JSON.parse(data.data)
  } catch (e) {
    json = data
  }
  const { method, params } = json
  console.log(data)

  switch (method) {
    case 'streamsUpdateAnt':
      updateStreamsAnt(params.streams)
      return
    case 'balanceChangedPurchasedCoins':
      balanceChangedPurchasedCoins(params.purchasedCoins, params.deltaPurchasedCoins, params.exp, params.action)
      return
    case 'balanceChangedReceivedCoins':
      if (!params.wasReceivedCoins) {
        checkIncomeNotification()
      }
      setStoreWrapper('auth.receivedCoins', params.receivedCoins)
      setStoreWrapper('auth.usdBalance', Math.trunc(params.receivedCoins / getState('intl.settings.coinsToMoney') * 100) / 100)
      return
    case 'callStarts':
      return startCall(params)
    case 'callEnds':
      return callEnds(params.caller, params.receiver)
    case 'updateOnline':
      updOnline(params.online, params.online_counter)
      return
    case 'chatMessage':
      if (!isUserMessageBanned(params.msgData.from) && !isMe(params.msgData.from)) {
        onNewMessage2(params.roomId, params.msgData)
      }
      return
    case 'addBuff':
      return addBuff2(params.buff, true)
    case 'delBuff':
      return dellBuff2(params.buff)
    case 'animatedLike':
      return animatedLikeReceived(params.fromId, getCurrentRoomId(), params.durations, true)
    case 'subscribe':
      return addFan(params.uid, params)
    case 'unsubscribe':
      return dellFan(params.uid)
    case 'vipPurchase':
      return vipPurchase(params)
    case 'banned':
      return setAccountBan(params.banId)
    case 'uncacheUser':
      return uncacheUser2(params.uid)
    case 'transaction':
      return transaction(params)
    case 'experienceChanged':
      return updateEXP(params.uid, params.experience)
    case 'deleteMessage':
      return deleteChatMessage(params.roomId, params.deletedMessage)
    case 'giftSent':
      return addGiftsMessage2(params)
    case 'newReview':
      return addLocalReview(params)
    case 'deleteReview':
      return delLocalReview(params)
    case 'updateEnergy':
      return updateMyEnergy(params)
    case 'updateEnergy':
      return updateMyEnergy(params)
  }
}

const ANOTHER_CONNECTION_CODE = 4013

const MAX_RECONNECT_DELAY = 5000
function onclose(data) {
  //закрытие с особым кодом говорит о том, что я вошел из другого места
  if (data.code === ANOTHER_CONNECTION_CODE) {
    openPosters()
    createPanelUniversal(ANOTHER_LOGIN)
    return
  }
  setTimeout(() => {
    createCoreAPIWSS()
  }, Math.random() * MAX_RECONNECT_DELAY)
  //TODO временно отключим
  createPanelUniversal(CONNECTION_LOST)
}

export function sendCorApiWsMessage(method, params, errorCb) {
  const data = JSON.stringify(params ? { method, params } : { method })
  try {
    if (instance && instance.readyState !== undefined && instance.readyState === instance.OPEN && instance.readyState !== instance.CONNECTING) {
      instance.send(data)
    } else {
      console.log('!!! game wss error ' + data)
      if (errorCb) {
        errorCb()
      }
    }
  } catch (e) {
    console.error(data)
    if (errorCb) {
      errorCb()
    }
  }
}

let authSession

export function authCoreSocket() {
  if (!getSessionId()) {
    return
  }
  if (getSessionId() === authSession) {
    return
  }
  // // loadMyEnergy()
  authSession = getSessionId()
  sendCorApiWsMessage('authorize', { sid: getSessionId() }, () => {
    authSession = null
    setTimeout(() => {
      authCoreSocket()
    }, 1000)
  })
}
