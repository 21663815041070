import { updateEXP } from '../components/Levels/EXPManager'
import { setStoreWrapper } from './StoreManager'
import { getMyUid } from './my'
import { COIN_ICON } from './stringUtils'
import { showSnackbarNew } from '../modules/app/factories'
import { getZLocaleValueF } from '../zustand/useAppStore'

export function balanceChangedPurchasedCoins(purchasedCoins, deltaPurchasedCoins, exp, action) {
    setStoreWrapper('auth.coins', purchasedCoins)
    setStoreWrapper('auth.purchasedCoins', purchasedCoins)
    if (action === 'returnLastPayment') {
        showSnackbarNew(getZLocaleValueF('last_payment_return_action', { '%coins%': COIN_ICON + deltaPurchasedCoins }))
    }
    updateEXP(getMyUid(), exp)
}
