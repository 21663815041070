import { set, wait } from 'cerebral/factories'
import { props, state } from 'cerebral'
import { getCodec } from '../actions'
import { send } from '../../socket/actions'
import { getParentDomain } from '@/utils/urlUtils'
import { getSessionId } from '@/utils'
import is from 'is_js'

import config from '@/config'
import { getState, setStoreWrapper } from '../../../utils/StoreManager'
import { getPrivateThemes } from '../../../utils/directCallThemeUtil'
import { getCoreUrl } from '../../../utils/api/apiURLUtil'

const { ftCoreUrl } = config

const createMetadata = () => {
  let metadata = getState('auth.storage.metadata') || {}
  if (metadata.needRotate && !is.ios()) {
    delete metadata.needRotate
  }

  if (is.ios() && !metadata.needRotate) {
    metadata.needRotate = true
  }

  const result = {
    ...metadata,
    needSub: getState('auth.subscriptionSettings.LIVE'),
    domain: getParentDomain(),
    theme: {
      id: 'reality_show',
      activities: [],
      privateActivities: [],
      privateShowThemes: getMyPrivateThemes()
    },
    ...getState('room.metadata'),
  }
  delete result.privateShow
  return result
}

export const saveMetadata = async () => {
  const metadata = createMetadata()
  fetch(getCoreUrl('/api/v1/broadcasts/metadata'), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      sid: getSessionId(),
      metadata: metadata
    })
  })

  setStoreWrapper('auth.storage.metadata',metadata)
}

export function getMyPrivateThemes() {
  const broadcastThemes = getPrivateThemes()
  const bannedPrivates = getState('room.bannedPrivates')
  const result = []

  for (const themeId in broadcastThemes) {
    if (bannedPrivates[themeId]) {
      continue
    }

    result.push(themeId)
  }
  return result
}

export default [
  wait(50),
  getCodec,
  // ({ get }) => ({
  //   method: 'createBroadcast',
  //   params: {
  //     roomId: get(state`app.roomId`),
  //     metadata: createMetadata({ get })
  //   }
  // }),
  // send,
  saveMetadata,
  // set(state`auth.storage.metadata`, props`params.metadata`)
]
