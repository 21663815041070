import { GETRequest } from '../utils/api/requestWrapper'
import { getCoreUrl } from '../utils/api/apiURLUtil'
import { updateStreamsAnt, updOnline } from '../modules/socket/actions'
import { useCallStore } from '../utils/call/callUtil'

export function getBaseData () {
    GETRequest(getCoreUrl('/api/v1/on_connect_data', true), (data)=>{
        // updateCoins(data.result)
        updOnline(data.online, data.online_counter)
        console.log(data.streams)
        updateStreamsAnt(data.streams)
        useCallStore.getState().setAllCalls(data.calls || {})
    }, (data)=>{
        console.log(data)
    }, true)
}
