import { create } from 'zustand'
import { getState, setStoreWrapper } from '../StoreManager'
import { getAgeRatingByUid } from '../../modules/posters/showInRoom'
import { imInRoom } from '../appUtil'
import { isMe } from '../checks'
import { sendAmplitudeEvent } from '../GAEvent'
import { showSnackbarNew } from '../../modules/app/factories'
import { getZLocaleValueF } from '../../zustand/useAppStore'
import { playAudio } from '../soundUtil'
import { getConfig } from '../urlUtils'
import { AFTER_PRIVATE_DELAY } from '../../modules/buffs'
import { addBuffNew } from '../buffUtils'

export const useCallStore = create(set => ({
    calls: {},
    setAllCalls: (calls) => {
        const result = Object.keys(calls).reduce((acc, key) => {
            const callData = calls[key]
            acc[callData.receiver] = {
                'caller': callData.caller,
                'receiver': callData.receiver,
                'call_theme': callData.call_theme,
                'call_mode': callData.call_mode,
                'model': callData.receiver,
                'viewer': callData.caller,
                'withCam': callData.call_mode === 'mic' || callData.call_mode === 'cam',
                'cost_per_minute': callData.cost_per_minute
            }
            return acc
        }, {})
        set({ calls: result })
        setStoreWrapper('posters.privates', result)
    },
    addCall: (callData) => {
        const { calls } = useCallStore.getState()
        calls[callData.receiver] = {
            'caller': callData.caller,
            'receiver': callData.receiver,
            'call_theme': callData.call_theme,
            'call_mode': callData.call_mode,
            'model': callData.receiver,
            'viewer': callData.caller,
            'withCam': callData.call_mode === 'mic' || callData.call_mode === 'cam',
            'cost_per_minute': callData.cost_per_minute
        }
        set({ calls })
        setStoreWrapper('posters.privates', calls)
    },
    deleteCall: (caller) => {
        const { calls } = useCallStore.getState()
        delete calls[caller]
        set({ calls })
        setStoreWrapper('posters.privates', calls)
    },
    getCallInRoom: (roomId) => {
        const { calls } = useCallStore.getState()
        return calls[roomId]
    },
    getCallCost: (roomId) => {
        const { getCallInRoom } = useCallStore.getState()
        const call = getCallInRoom(roomId)
        if (call) {
            return call.cost_per_minute
        }
        return null
    },

    getCallTheme: (roomId) => {
        const { getCallInRoom } = useCallStore.getState()
        const call = getCallInRoom(roomId)
        if (call) {
            return call.call_theme
        }
        return null
    },
}))

export function startCall(callData) {
    const { caller, receiver, withCam } = callData

    if (imInRoom(caller)) {
        if (isMe(caller)) {
            setStoreWrapper('room.roomEnterTime', Date.now())
            sendAmplitudeEvent('video_call', { progress: 'call_starts' })
            setStoreWrapper('app.isInPrivate', receiver)
            if (withCam) {
                setStoreWrapper('room.companionUid', caller)
            } else {
                setStoreWrapper('room.companionUid', null)
            }
            showSnackbarNew(getZLocaleValueF('private_show_starts_enjoy_head'))
        } else if (isMe(receiver)) {
            setStoreWrapper('app.isInPrivate', caller)
            showSnackbarNew(getZLocaleValueF('private_show_starts_for_model_head', { '%viewer%': caller }))
            if (withCam) {
                setStoreWrapper('room.soundMuted', false)
                setStoreWrapper('room.companionUid', caller)
            } else {
                setStoreWrapper('room.companionUid', null)
            }
            try {
                const broadcastingTime = (Date.now() - getState('room.broadcastStartTime')) / 1000
                if (broadcastingTime > 1 && broadcastingTime < 50000) {
                    const amData = {
                        broadcast_duration: Math.round(broadcastingTime),
                        broadcast_type: 'public',
                        end_reason: 'user',
                        content_rating: getAgeRatingByUid(receiver),
                        topic_id: 'reality_show'
                    }
                    sendAmplitudeEvent('topic_duration_model', amData)
                }
                setStoreWrapper('room.broadcastStartTime', Date.now())
            } catch (e) {
                console.error(e)
            }
        }
    }
    const chats = getState('chat.roomsMessages', {})
    chats[caller] = []
    chats[receiver] = []
    setStoreWrapper('chat.roomsMessages', chats)

    const roomSpectators = getState('chat.roomSpectators', {})
    roomSpectators[receiver] = [caller, receiver]

    setStoreWrapper('chat.roomSpectators', roomSpectators)
    if (isMe(receiver)) {
        playAudio(getConfig('soundUrl') + 'private_start.mp3')
    }

    useCallStore.getState().addCall(callData)
}

export function callEnds(caller, receiver) {
    if (isMe(caller) || isMe(receiver)) {
        setStoreWrapper('callAccepted', null)
        setStoreWrapper('room.companionUid', null)
        setStoreWrapper('app.isInPrivate', null)
        if (isMe(receiver)) {
            const broadcastingTime = (Date.now() - getState('room.broadcastStartTime')) / 1000
            if (broadcastingTime > 1 && broadcastingTime < 20000) {
                const amData = {
                    broadcast_duration: Math.round(broadcastingTime),
                    broadcast_type: 'private',
                    end_reason: 'user',
                    content_rating: getAgeRatingByUid(receiver),
                    topic_id: useCallStore.getState().getCallTheme(receiver) || 'reality_show'
                }
                sendAmplitudeEvent('topic_duration_model', amData)
            }
            playAudio(getConfig('soundUrl') + 'private_end.mp3')
            setStoreWrapper('room.broadcastStartTime', Date.now())
        } else {
            setStoreWrapper('room.streamInRoom', null)
        }
    }
    addBuffNew(receiver, AFTER_PRIVATE_DELAY)

    useCallStore.getState().deleteCall(caller)
}

