import { when } from 'cerebral/factories'
import { onconnect } from '../actions'
import { state } from 'cerebral'
import destroyPanel from '../../app/signals/destroyPanel'
import getAditionalResources from '../../app/chains/getAditionalResources'
import sendGoInRoom from '../../room/signals/sendGoInRoom'
import { setDefaultFilters } from '../../../utils/filtersUtil'
import { callSequence, setStoreWrapper } from '../../../utils/StoreManager'
import { selectFeed } from '../../filters/actions/feedUtil'
import { TYPE_CYBER, TYPE_LIVE_ONLY, TYPE_REAL_DATING } from '../../posts/signals/getPosts'
import { resetWSSReconnectTimeout } from '../../../utils/api/timeoutUtil'
import { isAIDatingApp, isDatingApp } from '../../../utils/appConfigUtil'
import { closeIfType, getTopPanelType } from '../../app/actions'

export default [
  ({ props, store, get }) => {
    console.log('wssOnConnect')
    resetWSSReconnectTimeout()
    getTopPanelType()
    closeIfType('connectionLostPanel')

    // console.log('countryFullName: ', props.params.countryFullName)
    // setStoreWrapper('countryFullName', props.params.countryFullName || 'United States')
  },
  // when(
  //   state`app.panels`,
  //   panels => panels.length > 0 && panels[panels.length - 1].type === 'connectionLostPanel'
  // ), {
  //   true: [destroyPanel],
  //   false: []
  // },
  onconnect, {
    //TODO временный церебрал костыль
    inOtherRoute: [
      // getAditionalResources,
    ],
    // inRoom: [
      // getAditionalResources,
      // when(state`posters.raw.${state`app.roomId`}`), {
      //   false: [],
      //   true: [sendGoInRoom]
      // }
    // ]
  }
]
