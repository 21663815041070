import config from '@/config'
import { GETRequest } from '../../../utils/api/requestWrapper'
import { getCoreUrl } from '../../../utils/api/apiURLUtil'
import { setStoreWrapper } from '../../../utils/StoreManager'

const { ftCoreUrl } = config

export const getTopUsers = async ({ store }) => {
  try {
    getTopUsers2()
    // const response = await fetch(`${ftCoreUrl}/api/v2/top-authors`)
    // const result = await response.json()
    // store.set('intl.usersTop', result['top-authors'])
  } catch (e) {
  }
}

export function getTopUsers2(){
  GETRequest(getCoreUrl('/api/v2/top-authors'), (data)=>{
    setStoreWrapper('intl.usersTop', data['top-authors'])
  })
}
