import { loadUser } from '@/utils/userLoader'
import { state } from 'cerebral'
import isVip from '../../../utils/isVip'
import { DAILY_COINS, DAILY_COINS_NOVIP, NO_DAILY_COINS } from '../../buffs'
import { isMe } from '../../../utils'
import { imVIP } from '../../../utils/my'
import { getUser } from '../../../utils/userLoader'
import { setStoreWrapper } from '../../../utils/StoreManager'

export default ({ props, store, get }) => {
  uncacheUser2(props.uid)
}

export function uncacheUser2(uid){
  const userData = getUser(uid)
  if (!userData) {
    return
  }

  loadUser(uid, () => {
    if (!isMe(uid)) {
      return
    }
    const userData = getUser(uid)
    if (!userData) {
      return
    }

    if (!userData.multi_buffs[NO_DAILY_COINS]) {
      if (imVIP()) {
        userData.multi_buffs[isVip() ? DAILY_COINS : DAILY_COINS_NOVIP] = [{
          start: Date.now() / 1000,
          buff_id: isVip() ? DAILY_COINS : DAILY_COINS_NOVIP
        }]
      }
    }

    for (const key in userData) {
      if (key === 'fans' || key === 'subs' || key === 'friends' || key === 'coins' || key === 'vip_subscription') {
        continue
      }
      setStoreWrapper(`auth.${key}`,userData[key])
    }
  }, true)
}
