import { state } from 'cerebral'
import { getUser, updateUser } from '../../../utils/userLoader'

export const delBuff = ({ props, store, get }) => {
  dellBuff2(props.buff)
}

export function dellBuff2(buff) {
  const user = getUser(buff.toUid)
  if (!user || !user.multi_buffs) {
    return
  }

  delete user.multi_buffs[buff.cardType]
  updateUser(buff.toUid, user)
}
