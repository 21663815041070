import { state } from 'cerebral'
import { initABCTest } from '@/utils/GAEvent'
import config from '@/config'
import { getConfigVersion, getLocaleVersion } from '../../../utils/bodyAttributeUtil'
import { getState, setStoreWrapper } from '../../../utils/StoreManager'
import { saveShopItems } from '../../shop/actions'
import { getSettings, getSettingsInt } from '../../../utils/settingsUtil'
import { loadCyberPosters } from '../../../utils/cyber/cyberUtil'
import { setLocales } from '../../../zustand/useAppStore'
import { baseAppSetup } from '../../../utils/app/baseAppSetup'

const { localesUrl, settingsUrl } = config

const setUIDirection = (locale, store, get) => {
    const availableLocales = get(state`intl.availableLocales`)
    if (!availableLocales[locale]) {
        if (locale === 'ar' || locale === 'he' || locale === 'ur' || locale === 'fa') {
            // setRtl(true)
            store.set('intl.direction', 'rtl')
        } else {
            // setRtl(false)
            store.set('intl.direction', 'ltr')
        }
    } else {
        const localeFormat = availableLocales[locale].rtl
        if (localeFormat !== undefined) {
            // setRtl(true)
            store.set('intl.direction', 'rtl')
        } else {
            // setRtl(false)
            store.set('intl.direction', 'ltr')
        }
    }
}

export const getLangCode = ({ store, get }) => {
    let result = get(state`intl.lang`)
    if (!result) {
        result = navigator.language.substring(0, 2)
        console.log('BROWSER_LANG ' + result)
        store.set('intl.lang', result)
    }
}

const loadingProgress = {}
export const loadSetting = async (url, stateString, callback, force) => {
    if (!loadingProgress[stateString]) {
        try {
            loadingProgress[stateString] = true
            const response = await fetch(settingsUrl + url)
            const result = await response.json()
            setStoreWrapper(stateString, result)
        } catch (e) {
            delete loadingProgress[stateString]
            console.warn('settings load error', settingsUrl + url, e)
        }
    }

    if (callback) {
        callback()
    }
}

export const loadSettings = async (get, store) => {
    if (get(state`intl.settings`)) {
        return
    }
    try {
        loadSetting('/settings' + getConfigVersion(), 'intl.settings', () => {
            const coins_items = getSettings('coins_items')

            if (coins_items) {
                setStoreWrapper('shop.successfullyLoaded', true)
                setStoreWrapper('shop.items', saveShopItems(coins_items))
            } else {
                setStoreWrapper('shop.successfullyLoaded', false)
            }

            //если логин был ДО того как приешл конфиг с балансом обновим долларовый баланс
            const receivedCoins = get(state`auth.receivedCoins`)
            if (receivedCoins) {
                //обновим баланс долларов
                const coinsToMoney = getSettingsInt('coinsToMoney')
                store.set('auth.usdBalance', Math.trunc(receivedCoins / coinsToMoney * 100) / 100)
            }
            initABCTest({ store, get })
            loadCyberPosters()
            baseAppSetup()
        })

        loadSetting('/effects' + getConfigVersion(), 'buffs.cards')
    } catch (e) {
        console.warn('settings load error', settingsUrl + '/all-configs', e)
    }
}

const loadLocale = async ({ locale, skin }) => {
    try {
        const response = await fetch(localesUrl + `/api/v1/result/${skin || 'main'}/${locale}.json` + getLocaleVersion())

        if (response.ok) {
            return await response.json()
        } else {
            throw new Error('locale load error')
        }
    } catch (e) {
        console.warn('Locale load error', locale, skin)
        if (skin) {
            return await loadLocale({ locale })
        }
        if (locale !== 'en') {
            return await loadLocale({ locale: 'en' })
        }

        return {}
    }
}

const startLocaleLoading = async ({ store, get }) => {
    const locale = get(state`intl.lang`)

    setUIDirection(locale, store, get)
    const localeData = await loadLocale({ locale, skin: getLocaleSkin() })

    store.merge('intl.content', localeData)
    const availableLocales = get(state`intl.availableLocales`) || {}
    setLocales(localeData, availableLocales[locale] ? availableLocales[locale].rtl : locale === 'ar' || locale === 'he' || locale === 'ur' || locale === 'fa', locale)
    setStoreWrapper('localeLoaded', true)
}

function getLocaleSkin() {
    const appSourceConfig = getState('intl.appSourceConfig')
    return (appSourceConfig && appSourceConfig.textSkinId) || 'main'
}

export const getLocaleRequest = async ({ store, get, path, props }) => {
    const localization = get(state`intl.content`)

    if (localization && props.useCache) {
        store.merge('intl.content', localization)
        startLocaleLoading({ store, get, props })

        return path.success()
    }

    await startLocaleLoading({ store, get, props })
    return path.success()
}
