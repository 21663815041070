import { AFTER_PRIVATE_DELAY, GOOD_FOR_12, GOOD_FOR_14, GOOD_FOR_16, GOOD_FOR_18 } from '@/modules/buffs'
import controller from '@/controller'
import { isBuffActive } from '@/utils/buffUtils'
import { getUser, isUserBannedMyCountry } from '@/utils/userLoader'
import is from 'is_js'
import { isTWA } from '@/utils/twa'
import { LEVEL_VIP, LIVE_PERMISSION } from '../../utils/subscriptionUtil'
import {
  isProfileVisible,
  isWaitForCall,
  posterVisibleStream,
  roomVisibleStreamWithNSFW
} from '../../utils/ageRating/ageRatingUtil'
import { streamComplaintCheck } from '../../utils/streamUtil'

export const isVipStream = uid => {
  const user = getUser(uid)
  if (user && user.subscriptionSettings &&
    user.subscriptionSettings[LIVE_PERMISSION] === LEVEL_VIP) {
    return true
  }

  return false
}

export const getAgeRatingByUid = uid => {
  let broadcastAgeRating = 99

  if (isBuffActive(uid, AFTER_PRIVATE_DELAY)) {
    return 98
  }
  if (isBuffActive(uid, GOOD_FOR_12)) {
    broadcastAgeRating = 12
  }
  if (isBuffActive(uid, GOOD_FOR_14)) {
    broadcastAgeRating = 14
  }
  if (isBuffActive(uid, GOOD_FOR_16)) {
    broadcastAgeRating = 16
  }
  if (isBuffActive(uid, GOOD_FOR_18)) {
    broadcastAgeRating = 18
  }

  return broadcastAgeRating
}

export const getBroadcast = uid => {
  return controller.getState('posters.raw')[uid]
}


const iOSversion = () => {
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    const v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/)
    return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)]
  }
}

const codecAvailableInIos = uid => {
  return true
  // const metadata = getStreamMetadata(uid)
  // if (metadata && metadata.codec && typeof metadata.codec === 'string') {
  //   if (metadata.codec.toUpperCase() === 'H264') {
  //     return true
  //   }
  //   const ver = iOSversion() || [0]
  //
  //   if (ver[0] >= 14) {
  //     return true
  //   }
  //   return false
  // }
  // return true
}

export const showInRoom = brData => {
  if (!brData || !brData.uid) {
    return false
  }
  const isInRoom = controller.getState('app.roomId') === brData.uid
  if (streamComplaintCheck(brData.uid)) {
    return false
  }

  if (brData.uid === controller.getState('auth.uid') ||
    brData.uid === controller.getState('app.isInPrivate')) {
    return true
  }

  if (isBuffActive(brData.uid, AFTER_PRIVATE_DELAY)) {
    if (isInRoom) console.log('@@@ block broadcast - author has rest buff')
    return false
  }

  if (isUserBannedMyCountry(brData.uid)) {//проверка на бан cтраны
    if (isInRoom) console.log('@@@ block broadcast - country ban')
    return false
  }

  if (controller.getState(`posters.privates.${brData.uid}`)) {
    if (isInRoom) console.log('@@@ block broadcast - in private')
    return false
  }

  if (is.ios() && !codecAvailableInIos(brData.uid)) {
    return false
  }

  if (isTWA()) {
    const brAgeRating = getAgeRatingByUid(brData.uid)
    if (brAgeRating > 18) {
      if (isInRoom) console.log('@@@ block broadcast - invalid rating', brAgeRating)
      return false
    }
    const allowNSFW = controller.getState('auth.stuff.allowNsfw')
    if (roomVisibleStreamWithNSFW(brData.uid)) {
      return true
    }

    if (isWaitForCall(brData.uid)) {
      if (isInRoom) console.log('@@@ block broadcast - rating', brAgeRating, 'allow NSFW', allowNSFW)
      return false//wait for call broadcasts
    }
  }

  if (!roomVisibleStreamWithNSFW(brData.uid)) {
    console.log('@@@ WEB roomVisibleStreamWithNSFW ', brData.uid)
    return false
  }

  return true
}

export default showInRoom
