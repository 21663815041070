import { state } from 'cerebral'
import config from '@/config'
import { isMe } from '../../../utils'

const { soundUrl } = config

export const onPrivateShowStart = async ({ store, get, props }) => {
  const { model, viewer, cost, withCam } = props
  store.set(`chat.roomsMessages.${model}`, [])
  store.set(`chat.roomsMessages.${viewer}`, [])
  store.set(`app.roomSpectators.${model}`, [model, viewer])

  const rawPoster = get(state`posters.raw.${model}`)
  if (rawPoster) {
    store.set(`posters.raw.${model}.cost`, cost)
  }
  store.set(`posters.privates.${model}`, { viewer, model, withCam })

  if (model === get(state`auth.uid`)) {
    try {
      const s = new Audio(soundUrl + 'private_start.mp3')
      await s.play()
    } catch (e) {
      console.log('sound not allowed:', e)
    }
  }

  if (!isMe(model) && !isMe(viewer)) {

  }
}

