import { onmessage, updateStreamsAnt } from '../actions'
import { set } from 'cerebral/factories'
import { props, state } from 'cerebral'
import { COIN_ICON } from '@/utils'
import controller from '@/controller'
import {
    addGiftsMessage,
    banMsg,
    checkChatMessage,
    deleteAllMessageFromUser,
    onNewMessage,
    onPrivateMessage
} from '../../chat/actions'
import chatSignals from '../../chat/signals'
import { addBuff, delBuff, } from '../../buffs/actions'
import uncacheUser from '../../app/actions/uncacheUser'
import { routeToFactory, showSnackbar, showSnackbarNew } from '../../app/factories'
import { onPurchase, onVipPurchase } from '@/utils/GAEvent'
import panelsOnPop from '../../app/signals/panelsOnPop'
import onConnect from './onConnect'
import { postersRoute } from '@/utils/routerHelpers'
import { getTopUsers } from '../../intl/actions'
import { NO_DAILY_COINS } from '../../buffs'
import { updateEXP } from '../../../components/Levels/EXPManager'
import { addFan, dellFan } from '../../fans/fansUtil'
import { callSequence, setStoreWrapper } from '../../../utils/StoreManager'
import { isApproveBuff, isVisibleBuff } from '../../../utils/buffUtils'
import { getMyProfile } from '../../../utils/my'
import { addLocalReview, delLocalReview } from '../../../utils/showcase/ratingUtil'
import { isMe } from '../../../utils'
import { checkIncomeNotification } from '../../auth/actions/notificationUtil'
import { animatedLikeReceived } from '../../../components/Levels/animatedLike'
import { getCurrentRoomId } from '../../../utils/navigation/navigationUtil'
import { askQuiz, quizWinner } from '../../../utils/quiz/quizUtil'
import { updateMyEnergy } from '../../../utils/energy/energyUtil'
import { getLocaleValue } from '../../../utils/localeUil'
import useDialogsStore from '../../../zustand/useDialogsStore'
import { deleteChatMessage } from '../../../utils/chat/chatUtil'
import * as Sentry from '@sentry/react'
import { callEnds, startCall } from '../../../utils/call/callUtil'
import { setAccountBan } from '../../../utils/ban/accountBanUtil'
import { balanceChangedPurchasedCoins } from '../../../utils/balanceUtil'
import { vipPurchase } from '../../../utils/userUtil'
import { transaction } from '../../../utils/paymentUtil'

export default [
    onmessage, {
        onConnect: [
            onConnect
            // getTopUsers
        ],
        authSuccess: [
            // uploadOneSignalId,
            // ({ get, path }) => {
            //     loadMyNewContentCounters()
            //     setStoreWrapper('upload.postKind', 'postProfile')
            //
            //     if (!get(state`auth.lang`)) {
            //         return path.sendLangToServer()
            //     }
            //     if (get(state`auth.lang`) !== get(state`intl.lang`)) {
            //         return path.changeLang()
            //     }
            //     return path.doNothing()
            // }, {
            //     changeLang: [
            //         ({ get }) => ({ locale: get(state`auth.lang`) }),
            //         changeLocale
            //     ],
            //     sendLangToServer: [
            //         ({ get }) => ({
            //             params: { lang: get(state`intl.lang`), country: null },
            //             method: 'setLangAndCountry'
            //         }),
            //         send,
            //     ],
            //     doNothing: []
            // },
            // set(state`auth.isSuccessful`, true),
            // // setDefaultFilter,
            // hideLoginPanels,
            // checkIfTranslationIsPaidOrPrivate, {
            //     doNothing: [],
            //     paidNoCoins: set(state`room.allowedPaidStreamId`, null),
            //     modelInPrivateWithOtherUser: showSnackbar(state`intl.content.private_show_already_in_show`, 5000, 'error')
            // },
            // // ({ store, get }) => {
            // //   const mustSend = get(state`socket.mustSend`)
            // //   if (mustSend.length) {
            // //     const ws = Ws()
            // //     mustSend.forEach(data => {
            // //       ws.send(data)
            // //       sendToWS2(data)
            // //     })
            // //     store.set('socket.mustSend', [])
            // //   }
            // // },
            // increment(state`auth.loginCount`),
            // checkUnreadComments,
            // getPost,
            // loadSubsAndFans,
            // context => {
            // },
            // initABCTest,
            //
            // //открытие панели вывода после авторизации, если было нужно
            // ({ store, get }) => {
            //     // eslint-disable-next-line no-undef
            //     if (get(state`app.panelRoute`) === 'payout' && getTopPanelType() !== 'payout') {
            //         store.push('app.panels', { type: 'payout' })
            //     } else if (get(state`app.panelRoute`) === 'dialog' && getTopPanelType() !== 'dialog') {
            //         controller.getSequence('app.prepareAndRouteTo')({
            //             url: messagesRoute,
            //             urlValue: (get(state`app.url`) || '').split('/')[2]
            //         })
            //     }
            // }
        ],
        startWatchRecord: [],
        authUnsuccess: [
            () => {
                Sentry.captureMessage('WSS auth error')
                Sentry.captureException('WSS auth error')
            }
            // ({ props, path }) => {
            //     if (props.params === 'deleted') {
            //         closeAllPanels()
            //         setStoreWrapper('deletedMode', 'temp')
            //         setStoreWrapper('genderSelectScreen', true)
            //         return path.doNothing()
            //     }
            //     return path.reLogin()
            // }, {
            //     doNothing: [],
            //     reLogin: [
            //         requestToken, {
            //             success: [
            //                 getAuthInfo
            //             ],
            //             error: [
            //                 generateFingerprint,
            //                 set(state`auth.token`, props`fingerprint`),
            //                 requestToken, {
            //                     success: [
            //                         getAuthInfo
            //                     ],
            //                     error: [
            //                         set(state`auth.isAuthComplete`, true)
            //                     ]
            //                 }
            //             ]
            //         }
            //     ],
            // }
        ],
        onPostLike: [],
        onGetTopPosts: [],
        onGetFeedPosts: [],
        onGetNewPosts: [],
        onGetPosts: [],
        onGetUserPosts: [],
        onBan: [
            // set(state`app.ban`, props`reason`),
            // set(state`app.banId`, props`fine_id`),
        ],
        privateStartError: [
            // () => {
            //     callSequence('app.panelsOnPop')
            // },
            // showSnackbar(state`intl.content.private_show_no_show_available`, 5000, 'error')
        ],
        paidActivity: [],
        loadPosters: [],
        updateOnline: [
            // updOnline,
        ],
        clearRoomHistory: []
        // chatSignals.clearChat
        ,
        deleteAllMessageFromUser: [
            // deleteAllMessageFromUser
        ],
        dialogMessage: [
            onPrivateMessage,
        ],
        chatMessage: [
            // checkChatMessage, {
            // notMy: onNewMessage,
            // block: [],
            // my: []
            // }
        ],
        addBuff: [
            // addBuff,
            // ({ props, get, path }) => {
            //     if (props.buff.cardType === NO_DAILY_COINS) {
            //         return path.doNothing()
            //     }
            //     let upMessage = get(state`intl.content.card_${props.buff.cardType}_descr`) ||
            //         get(state`intl.content.card_${props.buff.cardType}`)
            //
            //     if (upMessage && isMe(props.buff.toUid) && isVisibleBuff(props.buff.cardType, props.buff.toUid)) {
            //         props.upMessage = upMessage
            //         if (isApproveBuff(props.buff.cardType) && getMyProfile()) {
            //             upMessage += ' ' + getLocaleValue('fill_out_profile')
            //         }
            //         showSnackbarNew(upMessage,
            //             5000,
            //             'buff',
            //             {
            //                 buffId: props.buff.cardType
            //             }
            //         )
            //     }
            // }
        ],
        postModeration: [],
        animatedLike: [
            // ({ props }) => {
            //     animatedLikeReceived(props.params.fromId, getCurrentRoomId(), props.params.durations, true)
            // }
        ],
        subscribe: [
            // ({ props, get }) => {
            //     addFan(props.params.uid, props.params)
            // }
        ],
        unsubscribe: [
            // ({ props, get }) => {
            //     dellFan(props.params.uid)
            // }
        ],
        subscriptionRenewal: [],
        delBuff: [
            // delBuff
        ],
        broadcastsSpecialData: [],
        roomEnterDisable: [],
        roomHistory: [
            // onRoomHistory
        ],
        onAllowNsfw: [],
        vipPurchase: [
            // ({ props, store }) => {
            //     vipPurchase(props.params)
                // onVipPurchase(props.params)
                // store.set('auth.vip_subscription', {
                //     ...props.params,
                //     status: 'ACTIVE',
                // })
            // },
            // showSnackbar(state`intl.content.purchase_completed`, 5000, 'success'),
        ],
        // cardGameNewCard:[
        //   ({ props })=>{
        //     addGameCard(props.params.card_id, props.params.uid, props.params.card_type, props.params.strength)
        //   }
        // ],
        // cardGameWinner:[
        //   ({ props })=>{
        //     //TODO сообщение о победителе, можно писать в разных комнатах
        //     clearCardGame()
        //     // addGameCard(props.params.winner, props.params.uid, props.params.card_type, props.params.strength)
        //   }
        // ],
        // cardGameTick:[
        //   ({ props })=>{
        //     syncGame(props.params)
        //   }
        // ],
        // //добавление карточек пачкой
        // cardGameNewCards:[
        //   ({ props })=>{
        //     cardGameNewCards(props.params)
        //   }
        // ],
        loginnedFromAnotherPlace: [
            // ({ props, path }) => {
            //     // getState('authData')
            //     if (getState('authData') + 1500 > Date.now()) {
            //         return path.doNothing()
            //     }
            //     return path.logOut()
            // }, {
            //     doNothing: [],
            //     logOut: [
            //         () => ({ options: loggedFromAnotherPlacePanel.options }),
            //         createPanel,
            //         () => Ws().close(),
            //         () => ({ url: postersRoute }),
            //         prepareAndRouteTo,
            //     ],
            // }
        ],
        onLoadUsers: [],
        uncacheUser: [
            // uncacheUser
        ],
        transaction: [
            // ({ props }) => transaction(props.params)
        ],
        payoutError: [
            // showSnackbar(state`intl.content.get_money_error`, 5000),
        ],
        payoutSuccess: [
            //params: {result: 249, usd: 12.479999542236328, newBalance: 0}
            // showSnackbar(state`intl.content.get_money_wait`, 5000),
            // set(state`auth.usdBalance`, 0),
            // ({ get, props }) => sendAmplitudeEvent('payout', {
            //     sum_usd: Math.trunc((props.params.receivedCoins / get(state`intl.settings.coinsToMoney`)) * 100) / 100,
            //     payout_id: props.data.result,
            //     ...get(state`app.payoutData`)
            // }),
            // unset(state`app.payoutData`)
        ],
        banned: [
            // ({ props }) => {
            //     setAccountBan(props.params.banId)
            // }
        ],
        balanceChanged: [],
        balanceChangedReceivedCoins: [
            // ({ store, get, props }) => {
            //     checkIncomeNotification()
            //     const coinsToMoney = get(state`intl.settings.coinsToMoney`)
            //     setStoreWrapper('auth.receivedCoins', props.params.receivedCoins)
            //     store.set(state`auth.usdBalance`, Math.trunc(props.params.receivedCoins / coinsToMoney * 100) / 100)
            // }
        ],
        experienceChanged: [
            // ({ get, props }) => {
            //     updateEXP(props.params.uid, props.params.experience)
            // }
        ],
        balanceChangedPurchasedCoins: [
            // // set(state`auth.coins`, props`params.purchasedCoins`),
            // ({ get, props }) => {
            //     const params = props.params
            //     balanceChangedPurchasedCoins(params.purchasedCoins, params.deltaPurchasedCoins, params.exp, params.action)
            //     // updateEXP(props.params.uid, props.params.exp)
            //     //
            //     // //props.params.action  === vip_buy_coins
            //     // if (props.params.action === 'returnLastPayment') {
            //     //     const returned = props.params.deltaPurchasedCoins
            //     //
            //     //     const showSnackbar = controller.getSequence('app.showSnackbar')
            //     //     showSnackbar({
            //     //         text: get(state`intl.content.last_payment_return_action`)
            //     //             .replace('%coins%', COIN_ICON + returned),
            //     //         type: 'success'
            //     //     })
            //     // }
            // }
        ],
        createBroadcast: [],
        privateMessage: [],
        endBroadcast: [],
        privateShowStarts: [],
        directCallStart: [
            ({ props }) => {
                startCall({
                    'caller': props.viewer,
                    'receiver': props.model,
                    'call_theme': props.themeId,
                    'call_mode': props.withCam ? 'cam' : 'simple',
                    'model': props.model,
                    'viewer': props.viewer,
                    'withCam': props.withCam,
                    'cost_per_minute': props.cost
                })
            }
            // when(state`app.roomId`, props`model`, (roomId, model) => roomId === model), {
            //     true: [
            //         set(state`app.privateThemeId`, props`themeId`),
            //         checkIfUserModelOrViewer, {
            //             model: [
            //                 set(state`app.isInPrivate`, props`viewer`),
            //                 ({ get, props }) => {
            //                     //если я принял звонок в офлайн от юзера, то при завершении звонка мне надо выключить стрим
            //                     if (getState('callAccepted') === props.viewer) {
            //                         setStoreWrapper('endStreamAfterCall', props.viewer)
            //                     }
            //                     const viewerName = get(state`app.users.${props.viewer}.name`)
            //                     const rawMessage = get(state`intl.content.private_show_starts_for_model_head`)
            //                     const snackbarTitle = rawMessage.replace(/%viewer%/gi, viewerName)
            //                     return { snackbarTitle }
            //                 },
            //                 ({
            //                      get,
            //                      props
            //                  }) => ({ snackbarMessages: [props.snackbarTitle, get(state`intl.content.private_show_starts_for_model`)] }),
            //                 showPrivateStartMsg,
            //                 when(props`withCam`), {
            //                     true: [
            //                         set(state`room.soundMuted`, false),
            //                         set(state`room.companionUid`, props`viewer`),
            //                     ],
            //                     false: [
            //                         set(state`room.companionUid`, null),
            //                         set(state`app.isInPrivateWithVideo`, false),
            //                     ]
            //                 },
            //                 ({ store, get }) => {
            //                     const broadcastingTime = (Date.now() - get(state`room.broadcastStartTime`)) / 1000
            //                     if (broadcastingTime > 1 && broadcastingTime < 50000) {
            //                         const subscription_type = (get(state`auth.subscriptionSettings.${LIVE_PERMISSION}`) || '-1').toLowerCase()
            //                         const amData = {
            //                             subscription_type,
            //                             broadcast_duration: Math.round(broadcastingTime),
            //                             broadcast_type: 'public',
            //                             end_reason: 'user',
            //                             content_rating: get(state`auth.lastModerRating`) || getAgeRatingByUid(get(state`auth.uid`)),
            //                             topic_id: 'reality_show'
            //                         }
            //                         sendAmplitudeEvent('topic_duration_model', amData)
            //                     }
            //                     store.set('room.broadcastStartTime', Date.now())
            //                 },
            //             ],
            //             viewer: [
            //                 ({ store, props }) => {
            //                     store.set('room.roomEnterTime', Date.now())
            //                     sendAmplitudeEvent('video_call', { progress: 'call_starts' })
            //                 },
            //                 set(state`app.isInPrivate`, props`model`),
            //                 ({ get }) => ({ snackbarMessages: [get(state`intl.content.private_show_starts_enjoy_head`), get(state`intl.content.private_show_starts_enjoy`)] }),
            //                 showPrivateStartMsg,
            //                 when(props`withCam`), {
            //                     true: [
            //                         set(state`room.companionUid`, props`viewer`),
            //                         set(state`app.isInPrivateWithVideo`, true),
            //                     ],
            //                     false: [
            //                         set(state`room.companionUid`, null),
            //                         set(state`app.isInPrivateWithVideo`, false),
            //                     ],
            //                 }
            //             ],
            //             other: [
            //                 // showSnackbar(state`intl.content.private_show_starts_exit`, 5000, 'error')
            //             ]
            //         }
            //     ],
            //     false: [],
            // },
            // onPrivateShowStart
        ],
        privateCall: [
            // ({ props }) => {
            //     if (isBuffActive(props.params.fromUid, ROOM_CHAT_BAN, getMyUid())) {
            //         return
            //     }
            //     createCallPanel(props.params.fromUid, true, props.params.themeId)
            // }

            // ({ props }) => ({
            //   type: props.params.withCam ? 'callToModelWithCam' : 'callToModel'
            // }),
            // showSnackbar(state`intl.content.prvt_call`, 60000, props`type`, props`params.fromUid`)
        ],
        callToPrivateCancel: [
            // ({ props }) => {
            //     if (isPanelType(CALL_PANEL)) {
            //         closeCurrentPanel()
            //     }
            // }
        ],
        endPrivateShow: [],
        directCallEnd: [
            // onPrivateEnd
            ({ props }) => {
                callEnds(props.params.viewer, props.params.model)
            }
        ],
        setPaidBroadcasts2: [],
        setPaidBroadcasts: [
            // setPaidTranslations, {
            //     doNothing: [],
            //     needCoins: set(state`room.allowedPaidStreamId`, null),
            //     notNotified: [
            //         showSnackbar(state`intl.content.paid_broadcast_starts_viewer`, 5000),
            //     ]
            // }
        ],
        setPaidBroadcast: [
            // ({ props }) => ({
            //     params: {
            //         uid: props.params.model,
            //         cost: props.params.cost
            //     }
            // }),
            // setPaidTranslations, {
            //     doNothing: [],
            //     needCoins: set(state`room.allowedPaidStreamId`, null),
            //     notNotified: [
            //         showSnackbar(state`intl.content.paid_broadcast_starts_viewer`, 5000),
            //     ]
            // }
        ],
        dellPaidBroadcast: [
            // when(state`app.isInPrivate`), {
            //     true: [
            //         ({ get, path, props }) => {
            //             const uid = get(state`auth.uid`)
            //             const isInPrivateWith = get(state`app.isInPrivate`)
            //             const model = props.params
            //             if (model === uid) {
            //                 return path.isModel()
            //             } else if (model === isInPrivateWith) {
            //                 return path.isViewer()
            //             } else {
            //                 return path.isOther()
            //             }
            //         },
            //         {
            //             isModel: [
            //                 when(state`room.streamInRoom`), {
            //                     true: [
            //                         ({ get }) => {
            //                             const viewer = get(state`app.isInPrivate`)
            //                             const viewerName = get(state`app.users.${viewer}.name`)
            //                             const rawMessage = get(state`intl.content.private_show_viewer_left_show`)
            //                             const snackbarTitle = rawMessage.replace(/%viewer%/gi, viewerName)
            //                             return { snackbarTitle }
            //                         },
            //                         showSnackbar(props`snackbarTitle`, 5000, 'private'),
            //                         set(state`room.companionUid`, null),
            //                         sendCreateBroadcast
            //                     ],
            //                     false: []
            //                 },
            //                 set(state`app.isInPrivate`, null)
            //             ],
            //             isViewer: [
            //                 set(state`app.isInPrivate`, null)
            //             ],
            //             isOther: []
            //         }
            //     ],
            //     false: []
            // },
            // unsetPaidTranslation
        ],
        paidForWatching: [//зритель
            // ({ store, get, props }) => {
            //     store.set(state`auth.coins`, props.params.purchasedCoins)
            //     store.set(state`auth.usdBalance`, Math.trunc((props.params.receivedCoins / get(state`intl.settings.coinsToMoney`)) * 100) / 100)
            //     const roomId = get(state`app.roomId`)
            //     const subscription_type = (needSubscription(roomId, LIVE_PERMISSION) || '-1').toLowerCase()
            //     const amdata = {
            //         subscription_type,
            //         type: props.params.type,
            //         sent_coins: props.params.send,
            //         age_rating: getUserAgeRating(roomId),
            //         content_rating: getAgeRatingByUid(roomId),
            //         counterparty_id: roomId || '-1',
            //         product_type: 'view',
            //     }
            //
            //     if (get(state`app.isInPrivate`)) {
            //         amdata.topic_id = get(state`app.privateThemeId`)
            //         amdata.broadcast_type = 'private'
            //         sendAmplitudeEvent('topic_pay', amdata)
            //     } else {
            //         const metadata = getStreamMetadata(roomId) || {}
            //         amdata.topic_id = (metadata.postThemeId || '-1') + ''
            //         amdata.broadcast_type = 'public'
            //         sendAmplitudeEvent('topic_pay', amdata)
            //     }
            // }
        ],
        paidForWatchingReceived: [//модель
            // ({ store, get, props }) => {
            //     store.set(state`auth.coins`, props.params.purchasedCoins)
            //     store.set(state`auth.usdBalance`, Math.trunc((props.params.receivedCoins / get(state`intl.settings.coinsToMoney`)) * 100) / 100)
            // }
        ],
        clearAllPaid: [],
        cantEnterInRoom: [
            // ({ props, path }) => {
            //     if (props.params === 'inPrivate') return path.inPrivate()
            //     if (props.params === 'noCoinsForWatching') return path.stopWatch()
            //     if (props.params.requiredSubscriptionType) return path.stopWatch()
            //
            //     return path.unknownReason()
            // }, {
            //     stopWatch: set(state`room.allowedPaidStreamId`, null),
            //     unknownReason: [
            //         routeToFactory(postersRoute),
            //     ],
            //     inPrivate: [
            //         routeToFactory(postersRoute),
            //         showSnackbar(state`intl.content.private_show_already_in_show`, 5000, 'error')
            //     ]
            // }
        ],
        paidLike: [],
        removeMessage: [
            // banMsg
        ],
        deleteMessage: [
            // ({ props }) => deleteChatMessage(props.params.roomId, props.params.deletedMessage)
        ],
        giftSent: [
            // ({ props }) => addGiftsMessage2(props.params)
        ],
        onGetGiftTop: [
            // set(state`room.giftTops.${props`params.topId`}`, props`params`),
        ],
        reloadSettings: [
            //TODO: обновить наcтройки
        ],
        subscribed: [
            // () => {
            //     console.log('sbsb')
            // }
        ],
        undefined: [],
        doNothing: [],
        newReview: [
            // ({ props }) => addLocalReview(props.params)
        ],
        deleteReview: [
            // ({ props }) => delLocalReview(props.params)
        ],
        onGetLastCaller: [],
        onCommentMarkRead: [],
        onCallToPrivate: [],
        streamsUpdate: [],
        streamsUpdateAnt: [
            // ({ props, get, store }) => updateStreamsAnt(props.params)
        ],
        newPost: [],
        quizQuestion: [
            ({ props, get, store }) => {
                askQuiz(props.params.quiz)
            }
        ],
        quizWinner: [
            ({ props, get, store }) => {
                quizWinner(props.params)
            }
        ],
        updateEnergy: [
            // ({ props, get, store }) => {
            //     updateMyEnergy(props.params)
            // }
        ],
        updateLastRead: [
            ({ props, get, store }) => {
                useDialogsStore.getState().updateLastRead(props.params)
            }
        ],
        quizFinished: [],
        unknownMethod: [
            ({ props }) => {
                console.warn('unknown socket method:', props.data)
                //throw new Error('unknown socket method')
            }
        ]
    }
]
