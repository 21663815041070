import { state } from 'cerebral'
import { getLang } from '../../../zustand/useAppStore'
import { setStoreWrapper } from '../../../utils/StoreManager'

export function setMarketingMediums(domainMarketingMediums){
  // const domainMarketingMediums = get(state`intl.appSourceConfig.marketingMediums`)
  const lang = getLang()
  if (domainMarketingMediums && lang) {
    const marketingMediumsObj = {}
    domainMarketingMediums.map(value => {
      if (!marketingMediumsObj[value.platform]) {
        marketingMediumsObj[value.platform] = {}
      }
      if (value.language === lang) {
        marketingMediumsObj[value.platform][lang] = value
      }
      if (value.language === 'en') {
        marketingMediumsObj[value.platform].en = value
      }
    })
    const marketingMediums = []
    Object.keys(marketingMediumsObj).forEach(platform => {
      const item = marketingMediumsObj[platform]
      if (item[lang]) {
        marketingMediums.push(item[lang])
      } else if (item.en) {
        marketingMediums.push(item.en)
      }
    })
    if (marketingMediums.length) {
      setStoreWrapper('intl.marketingMediums', marketingMediums)
    }
  }
}
