import { getAppConfig, isAIDatingApp, isDatingApp } from '../appConfigUtil'
import { selectFeed } from '../../modules/filters/actions/feedUtil'
import { TYPE_CYBER, TYPE_LIVE_ONLY, TYPE_REAL_DATING } from '../../modules/posts/signals/getPosts'
import { callSequence, setStoreWrapper } from '../StoreManager'
import { getAgeRatingByUid } from '../../modules/posters/showInRoom'
import { updateAgeRating } from '../ageRating/ageRatingUtil'
import { setMarketingMediums } from '../../modules/intl/actions'
import { getMyUid } from '../my'

export function baseAppSetup() {
    const appSourceConfig = getAppConfig()

    setStoreWrapper('intl.appSourceConfig', appSourceConfig)

    const feed = isAIDatingApp() ? TYPE_CYBER : (isDatingApp() ? TYPE_REAL_DATING : TYPE_LIVE_ONLY)
    selectFeed(feed)

    if (appSourceConfig.tagsIdExcluded) {
        setStoreWrapper('posts.tagsIdExcluded', appSourceConfig.tagsIdExcluded)
    }

    callSequence('intl.getLocale')

    setStoreWrapper('auth.lastModerRating', getAgeRatingByUid(getMyUid()))

    // updateStreamsAnt(streamsAnt)
    // store.set('app.paidRooms', paidBroadcasts)

    // if (country) {
    //     store.set('intl.country', country.toLowerCase())
    // } else {
    //     store.set('intl.country', 'us')
    // }

    updateAgeRating(appSourceConfig)
    setMarketingMediums(appSourceConfig.marketingMediums)

    // try {
    //   updOnline({ props: { online, totalCount }, store, get })
    // } catch (error) {
    //   console.warn('cant parse online', online, error)
    // }
    setStoreWrapper('app.isReady', true)
    /** Возможно лишнее
     if (route === 'room') {
     callSequence('room.sendGoInRoom')
     }
     */
}
